/* colors
#8ecae6 // light blue
#219ebc // teal
#023047 // dark blue
#ffb703 // gold
#fedade // pink
#c7dbda // light teal
*/

@font-face {
  font-family: "Moret";
  src: url('./assets/Moret-Book.otf');
}
/* paraphraph */
@import url('https://fonts.googleapis.com/css2?family=Corinthia:wght@400;700&family=Manrope&display=swap');

/* buttons, subtitles */
@font-face {
  font-family: "Hamburg Hand";
  src: url('./assets/HamburgHand-Regular.otf');
}



html, body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  max-width: 100%;
  background-color: #c7dbda;
  z-index: 1;
  overscroll-behavior: none;
}

/* PICTURES */
.picturesContainer{
  border: 1px solid black;
  background-color: #97bfbc;
  background-image: url(./assets/marble.jpg);
  background-size: cover;
  margin: 0;
  padding: 0;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
  margin-top: 2%;
  padding-top: 1%;
  padding-bottom: 3%;
  /* border: 2px black solid; */
  border-radius: 15px;
}

.picturesContainer p {
  /* margin-top: 5%; */
  font-family: "Manrope";
  color: black;
  text-align: center;
}

.pictures {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  /* display: flex; */

}

.picture {
  /* border: 1px black solid; */
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 15vw;
  height: 15vw;
  margin: 1%;
  -webkit-transition: transform .5s, box-shadow .5s;
  -moz-transition: transform .5s, box-shadow .5s;
  -o-transition: transform .5s, box-shadow .5s;
  transition: transform .5s, box-shadow .5s;
}

.bigPicture {
  border: none;
  background-size: contain;
  transform: scale(3.5);
}
.bigPicture:nth-child(4n){
  transform: translateX(-10vw) scale(3.5);
}
.bigPicture:nth-child(4n+1){
  transform: translateX(10vw) scale(3.5);
}
/* END PICTURES */

/* ABOUTME */
.aboutmeContainer {
  width: 100%;
  background-image: url(./assets/marble.jpg);
  background-size: cover;
  background-position: bottom;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.aboutme h1 {
  padding-left: 18%;
  /* text-shadow: 1px 1px 2px black; */
  width: 50%;
  padding-top:5%;
  color: #023047;
  font-family: "Moret";
}

.aboutmeContent p {
  font-family: "Manrope";
  line-height: 1.5;
  padding-left: 18%;
  padding-bottom: 8%;
  /* color:#023047; */
  width: 35%;
}

.aboutmeContainer {
  position: relative;
}

.aboutmeContainer img {
  background-image: url(./assets/img-bg.png) ;
  background-size: contain ;
  background-repeat: no-repeat;
  z-index: 1;
  position: absolute;
  bottom: -.5%;
  right: 18%;
  height: 95%;
}
/* END ABOUTME */

/* CONTACTME */
.contactme {
  background-color: #c7dbda;
  border: solid 1px #666;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  text-align: center;
  padding-bottom: 2%;
  box-shadow: 3px 3px 10px black;
}
.contactmeContainer {
  border-top: 1px black solid;
  border-bottom: 1px black solid;
  padding-top: 10%;
  padding-bottom: 10%;
  background-image: url(./assets/dreamstime_xxl_193424919.jpg);
  background-size: cover;
  background-position: center;
}
.formItem {
  width: 90%;
  background-color: #d7e8e7;
}

.contactme h1 {
  font-family: "Moret";
  color:#023047;
}

.button{
  padding-top: 5%;
}
/* END CONTACTME */

/* SERVICES */
.services {
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  padding-bottom: 4%;
  justify-content: space-around;
  text-align: center;
}

.servicesContainer h1 {
  font-family: 'Moret';
  color: #023047 ;
  padding-top: 5%;
  text-align: center;
  width: 100%;
  padding-bottom: 3%;
}

.services .item h1 {
  color: #023047;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.services .item {
  width: 25%;
}

.services .quote {
  width: 80%;
  padding-left: 10%;
  margin-top: -5%;
}

.services img {
  background-image: url(./assets/icon-bg.png);
  background-size: contain;
  height: 20vh;
}

/* END SERVICES */


/* TESTIMONIALS */
.testimonials {
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 4%;
}

.testimonialsContainer h1 {
  font-family: 'Moret';
  color: #023047 ;
  padding-top: 5%;
  text-align: center;
  width: 100%;
}

.testimonials .quote {
  font-family: "Manrope";
}

.signature {
  font-family: 'Corinthia';
}

.testimonials .item {
  padding: 3%;
}
.items {
  display: flex;
  width: 50%;
}

.item {
  width: 50%;
}


/* END TESTIMONIALS */

/* FOOTER */
.footer {
  font-family: "Manrope";
  color:#023047;
  width: 60%;
  display: flex;
  justify-content: space-around;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 3%;
}

.footercol h1{
  font-family: "Moret";
}

.footercol a{
  text-decoration: none;
  color:#023047;
  -webkit-transition: opacity .1s;
  -moz-transition: opacity .1s;
  -o-transition: opacity .1s;
  transition: opacity .1s;
}

.social {
  width: 20%
}


.social img {
  height: 3vh;
  padding-right: 15%;
  -webkit-transition: opacity .1s;
  -moz-transition: opacity .1s;
  -o-transition: opacity .1s;
  transition: opacity .1s;
}

.footer a:hover {
  cursor: pointer;
  opacity: .5;
}

.createdby {
  font-family: "Manrope";
  width: 94%;
  text-align: center;
  padding: 3%;
  color: #023047;
  padding-bottom: 5%;
}

.createdby a{
  padding: 1px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  -webkit-transition: background-color .25s;
  -moz-transition: background-color .25s;
  -o-transition: background-color .25s;
  transition: background-color .25s;
}

.createdby a:hover {
  background-color:white;
  color: #023047;
}
/* END FOOTER */

/* HOMEPAGE */
.home {
  background-image: url(./assets/background.JPG);
  background-color: #c7dbda;
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
  height: 101vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home h1 {
  text-align: center;
  margin-bottom: 5%;
  position: absolute;
  font-family: "Moret";
  color: #023047;
}


.bookNow {
  border-radius: 15px;
  margin-top: 30%;
  background-color: #fedade;
  font-family: "Hamburg Hand";
  padding: 2.5%;
  padding-top: 1.25%;
  padding-bottom: 1.25%;
  -webkit-transition: padding .25s;
  -moz-transition: padding .25s;
  -o-transition: padding .25s;
  transition: padding .25s;
}

.bookNow a{
  color: #023047;
  text-decoration: none;
}

.bookNow:hover {
  cursor: pointer;
  padding: 2.75%;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
}
/* END HOMEPAGE */

/* NAVIGATION */
.navContainer {
  margin-left: 10%;
  z-index: 2;
  width: 80vw;
  top: 40px;
  position: absolute;
  display: flex;
  justify-content: center;
  height: 7vh;
}

.wide {
  border-bottom: 1px black solid;
  background-color: #fff;
  margin-left: 0%;
  top: 0px;
  position: fixed;
  animation: wide 1s ease forwards;
}

.thin {
  margin-left: 10%;
  top: 40px;
  position: absolute;
  animation: narrow 1s ease forwards;
}

@keyframes wide {
  from {
    margin-left: 10%;
    width: 80vw;
  }
  to {
    margin-left: 0%;
      width: 100%;
  }
}

@keyframes narrow {
  from {
    margin-left: 0%;
    width: 100%;
  }
  to {
    margin-left: 10%;
    width: 80vw;
  }
}

.nav img {
  height: 9vh;
}

.thin img {
  animation: large .5s ease forwards;
}

.wide img {
  animation: small .5s ease forwards;
}

@keyframes small {
  from {
    height: 9vh;
    padding: 0%;
  }
  to {
    height: 6vh;
    padding: 3%;
  }
}

@keyframes large {
  from {
    height: 6vh;
  }
  to {
    height: 9vh;
  }
}

.nav{
  font-family: "Hamburg Hand", sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
  height: 7vh;
}
.nav div {
  flex: 1;
  display: flex;
  padding: 3%;
  justify-content: space-around;
}

.nav a {
  color: #023047;
  -webkit-transition: color .1s;
  -moz-transition: color .1s;
  -o-transition: color .1s;
  transition: color .1s;
    text-decoration: none;
}

.nav:hover {
  cursor: pointer;
}

.nav a:hover {
  color: #a09597;
}
/* END NAVIGATION */

@media screen and (orientation:portrait) {
  .home {
    background-image: url(./assets/mobile.JPG);
    background-position: center;
  }
  .home .bookNow {
    margin-top: 65%;
    padding: 4%;
  }
  .home h1{
    margin-bottom: 0;
  }
  .nav img {
    height: 6vh;
  }

  .thin div{
    justify-content: space-between;
  }

  .thin img {
    animation: large .5s ease forwards;
  }

  .wide img {
    animation: small .5s ease forwards;
  }

  @keyframes small {
    from {
      height: 6vh;
      padding: 0%;
    }
    to {
      height: 3vh;
      padding: 3%;
    }
  }

  @keyframes large {
    from {
      height: 3vh;
    }
    to {
      height: 6vh;
    }
  }
  .testimonials {
    flex-direction: column;
    width: 80%;
    margin-left: 10%;
  }

  .aboutme h1 {
    padding-left: 20%;
    /* text-shadow: 1px 1px 2px black; */
    width: 70%;
  }

  .aboutmeContent p {
    padding-left: 20%;
    width: 60%;
  }

  .aboutmeContainer {
    overflow: hidden;
  }

  .aboutmeContainer img {
    position: relative;
    margin-bottom: -2%;
    margin-left: 42%;
    width: 50%;
  }

  .servicesContainer{
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .services .item {
    width: 25%;
  }

  .services img {
    background-image: url(./assets/icon-bg.png);
    background-size: contain;
    height: 9vh;
  }
  .contactme {
    width: 60%;
  }
  .social img {
    height: 2vh;
  }
  .social h1{
    width: 200%;
  }
  .social div {
    width: 120%;
    display: flex;
    justify-content: space-between;
  }
  .items {
    display: flex;
    width: 100%;
  }
  .testimonials .item {
    padding: 0;
    padding-left: 3%;
    padding-right: 3%;
  }

  @keyframes wide {
    from {
      margin-left: 5%;
      width: 90vw;
    }
    to {
      margin-left: 0%;
        width: 100%;
    }
  }

  @keyframes narrow {
    from {
      margin-left: 0%;
      width: 100%;
    }
    to {
      margin-left: 5%;
      width: 90vw;
    }
  }
  .footer {
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .createdby {
    padding-bottom: 10%;
  }
  .picture {
    width: 30vw;
    height: 30vw;
    margin: 2%;
  }
  .picturesContainer {
    margin-bottom: 15%;
  }
  .bigPicture:nth-child(odd){
    transform: translateX(16vw) scale(3);
  }
  .bigPicture:nth-child(even){
    transform: translateX(-16vw) scale(3);
  }
}
